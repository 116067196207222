import CloseIcon from '@assets/icons/closeRedIcon.svg';
import { ReactElement, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

interface IMiniModal {
  parent: Element;
  direction: 'up' | 'down' | 'right' | 'left';
  headerTitle: string;
  body: ReactElement;
  onHide: () => void;
  classname?: string;
}

export const MiniModal = ({
  parent,
  direction,
  headerTitle,
  body,
  onHide,
  classname,
}: IMiniModal) => {
  const popupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        event.stopImmediatePropagation();
        event.preventDefault();
        onHide();
      }
    };

    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [onHide]);

  return ReactDOM.createPortal(
    <div
      className={`mini-modal-popup bg-white absolute flex flex-col z-[99999] rounded-[16px] drop-shadow-2xl ${classname}`}
      style={styleConfigure(parent, direction)}
      ref={popupRef}
    >
      <header className='flex justify-between items-center p-[16px]'>
        <span />
        <p className='font-medium text-large'>{headerTitle}</p>
        <div className='cursor-pointer' onClick={onHide}>
          <img src={CloseIcon} width={24} height={24} />
        </div>
      </header>
      <main className='px-[18px] pb-[18px]'>{body}</main>
    </div>,
    document.querySelector('body')!,
  );
};

const styleConfigure = (
  parent: IMiniModal['parent'],
  direction: IMiniModal['direction'],
) => {
  const rect = parent.getBoundingClientRect();

  switch (direction) {
    case 'up':
      return { top: rect.top };
    case 'down':
      return { top: rect.bottom };
    case 'right':
      return {
        left: rect.right + 20,
        top: rect.top,
      };
    case 'left':
      return {
        left: rect.left,
        top: rect.top,
      };
  }
};
