export const getQueryParams = (query: string) => ({
  chatId:
    process.env.REACT_APP_DEV === 'true'
      ? 1
      : query?.split('-')[0] ?? undefined,
  taskId:
    process.env.REACT_APP_DEV === 'true'
      ? undefined
      : +query?.split('-')[1] ?? undefined,
});
