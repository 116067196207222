import { Switcher } from '@ui/Switcher/Switcher';
import { useState } from 'react';
import { FileMenu } from '../FileMenu';
import { ITaskAndSubtasksComponent } from '../TaskAndSubTasksComponent';

const sliderElements = ['Файлы', 'Комментарии'];

export const CommentsFilesMenu = ({
  task,
  setTask,
}: ITaskAndSubtasksComponent) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className='w-full z-[2] p-[16px] backdrop-blur-smr relative'>
      <div className='absolute top-0 left-0 w-full z-[2] p-[16px] backdrop-blur-sm'>
        <Switcher
          activeIndex={activeIndex}
          onClick={(index) => setActiveIndex(index)}
          elements={sliderElements}
        />
      </div>

      <section className='min-w-full h-full flex flex-col gap-[25px] pt-[48px] overflow-auto'>
        <FileMenu setTask={setTask} task={task} />
      </section>
    </div>
  );
};
