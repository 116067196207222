import fileIcon from '@assets/icons/file.png';
import { extraLightHapticFeedback } from '@common/telegramHaptic';
import { useLogic } from '@hooks/storeHook';
import { AttachIcon } from '@pages/icons/attachIcon';
import { ITabElement, TabList } from '@ui/TabList/TabList';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { ITaskAndSubtasksComponent } from './TaskAndSubTasksComponent';

export const FileMenu = observer(
  ({ setTask, task }: ITaskAndSubtasksComponent) => {
    const logic = useLogic();

    // const [src, setSrc] = useState<string | null>(null)

    const onDownloadFile = async (fileId: string) => {
      const fileLink = `https://api.dev.tontask.com/tasks/${task.id!}/files/${fileId}`;
      const a = document.createElement('a');
      a.href = fileLink;
      a.target = '__blank';
      a.download = 'file';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    };

    const isRussian =
      //@ts-ignore
      Telegram?.WebApp?.initDataUnsafe?.user?.language_code === 'ru' ?? false;

    //@ts-ignore
    const elements: ITabElement[] =
      task!.file && task!.file.length > 0
        ? task!.file.map((e) => ({
            title: e.original_name,
            subtitle: moment(e.created_at).format(
              `MMM DD, YYYY [${isRussian ? 'в' : 'at'}] HH:mm`,
            ),
            onClick: () => onDownloadFile(e.tg_file_id),
            iconHeight: 40,
            iconWidth: 40,
            mainIcon: e.preview ?? fileIcon,
          }))
        : task!.newFiles
          ? task!.newFiles.map((e) => ({
              title: e.blob.name,
              subtitle: moment(e.created_at).format(
                `MMM DD, YYYY [${isRussian ? 'в' : 'at'}] HH:mm`,
              ),
              onClick: () => {},
              iconHeight: 40,
              iconWidth: 40,
              mainIcon: e.preview ?? fileIcon,
            }))
          : [];

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      handleFile(event!.target!.files![0]);
    };

    const handleFile = async (file: File) => {
      // Если файла нет - ничего не делать
      if (!file) return;

      if (task?.id) await logic.addFileToTask(task.id, file);
      else
        setTask('newFiles', [
          ...task.newFiles!,
          {
            preview: file.type.includes('image')
              ? URL.createObjectURL(file)
              : null,
            blob: file,
            created_at: new Date().toString(),
          },
        ]);
      extraLightHapticFeedback();
    };

    return (
      <div className='flex flex-col justify-between h-full'>
        <TabList elements={elements} isDarkTheme={false} />
        <label className='flex gap-6 w-fit items-center py-[12px] pl-[15px]  cursor-pointer'>
          <input
            className='flex items-center gap-6 py-3 border-0 w-fit'
            type='file'
            id='file-upload'
            onChange={handleFileChange}
          />
          <div className='w-6 h-6 flex justify-center items-center'>
            <AttachIcon />
          </div>
          <span className='text-blue text-[16px]'>Прикрепить файл</span>
        </label>
      </div>
    );
  },
);
