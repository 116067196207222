export const PrevIcon = () => (
  <svg
    width='7'
    height='12'
    viewBox='0 0 7 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M0.200054 6.48297C-0.0666845 6.21623 -0.0666845 5.78377 0.200054 5.51703L5.51703 0.200054C5.78377 -0.0666847 6.21623 -0.0666846 6.48297 0.200054C6.74971 0.466792 6.74971 0.899261 6.48297 1.166L1.64897 6L6.48297 10.834C6.74971 11.1007 6.74971 11.5332 6.48297 11.7999C6.21623 12.0667 5.78377 12.0667 5.51703 11.7999L0.200054 6.48297Z'
      fill='#007AFF'
    />
  </svg>
);
