export const CompleteIcon = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M21.1976 5.27096C21.5552 5.61613 21.5654 6.18589 21.2202 6.54355L9.79101 18.3862C9.6214 18.5619 9.38765 18.6612 9.14341 18.6612C8.89917 18.6612 8.66541 18.5619 8.49581 18.3862L2.78121 12.4649C2.43604 12.1072 2.44616 11.5374 2.80382 11.1923C3.16148 10.8471 3.73124 10.8572 4.07641 11.2149L9.1434 16.4652L19.925 5.29356C20.2702 4.9359 20.8399 4.92578 21.1976 5.27096Z'
      fill='#00BD30'
    />
  </svg>
);
