import { IChat } from '@@types/types';
import { useLogic, useStore } from '@hooks/storeHook';
import { observer } from 'mobx-react-lite';

export const ProjectMenu = () => {
  const { allChats } = useStore();
  return (
    <aside className='flex flex-col gap-9 min-w-[345px] h-full bg-greySecondary py-9 px-[18px] -tracking-[0.4]'>
      <h1 className='text-[36px]'>Проекты</h1>
      <ul className='flex flex-col gap-[6px]'>
        {allChats.map((e) => (
          <ProjectCard key={e.id} chat={e} />
        ))}
      </ul>
    </aside>
  );
};

const ProjectCard = observer(({ chat }: { chat: IChat }) => {
  const logic = useLogic();
  const store = useStore();

  const onClick = async () => {
    store.chat_id = chat.id;
    await logic.loadDataWithoutLoader();
  };

  return (
    <li
      className={`h-[50px] bg-transparent ${store.chat_id === chat.id && 'bg-white'} rounded-normal flex items-center justify-between px-[10px] cursor-pointer transition-colors duration-300`}
      onClick={onClick}
    >
      <p>{chat.title}</p>
      <div className='flex gap-[10px]'>
        {/* <img src={BellIcon} width={21} height={21} /> */}
        <div className='bg-blue rounded-[50%] h-[21px] w-[21px] flex items-center justify-center text-white text-bigger'>
          2
        </div>
      </div>
    </li>
  );
});
