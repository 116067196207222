export const NextIcon = () => (
  <svg
    width='7'
    height='12'
    viewBox='0 0 7 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M6.48293 5.51703C6.74967 5.78377 6.74967 6.21623 6.48293 6.48297L1.16596 11.7999C0.899217 12.0667 0.466749 12.0667 0.20001 11.7999C-0.0667281 11.5332 -0.0667281 11.1007 0.20001 10.834L5.03401 6L0.200011 1.166C-0.0667277 0.899261 -0.0667277 0.466792 0.200011 0.200054C0.466749 -0.0666849 0.899218 -0.0666849 1.16596 0.200054L6.48293 5.51703Z'
      fill='#007AFF'
    />
  </svg>
);
