import { LoadState } from '@@types/loadState';
import {
  DeskTypes,
  IAllTasks,
  IChat,
  IChatInfo,
  IFile,
  ITask,
} from '@@types/types';
import { action, computed, observable } from 'mobx';
import moment from 'moment';

export class Store {
  @observable
  rootLoading = LoadState.Initial;

  @observable
  loading = LoadState.Initial;

  @observable
  chatInfo: IChatInfo | undefined;

  @observable
  allTasks: ITask[] = [];

  @observable
  allChats: IChat[] = [];

  @observable
  tasks: IAllTasks = {};

  @observable
  selectedTask: ITask | null = null;

  @observable
  chat_id: number | undefined = undefined;

  @observable
  user_id: number | undefined = undefined;

  @computed
  get activeDesks() {
    return this.chatInfo?.desks.filter((e) => e.deleted === 0) ?? [];
  }

  @computed
  get activeMyTasks() {
    return this.allTasks.filter(
      (e) => e.status !== 2 && e.desk?.type !== DeskTypes.DONE,
    );
  }

  @computed
  get mergedSortedTasks() {
    const tasks: ITask[] = [];
    Object.keys(this.tasks).forEach((key) => {
      this.tasks[key].forEach((e) => tasks.push(e));
    });

    tasks
      .sort((a, b) => a.number - b.number)
      .sort((a, b) => {
        if (a.deadline === null) return 1;
        if (b.deadline === null) return -1;

        const deadlineA = moment(a.deadline);
        const deadlineB = moment(b.deadline);

        return deadlineA.diff(deadlineB);
      })
      .sort((a, b) => b.priority - a.priority);

    return tasks.filter((e) => e.status !== 2);
  }

  @action.bound
  updateTask(task: ITask) {
    for (const key in this.tasks) {
      if (this.tasks[key].find((e) => e.id === task.id))
        this.tasks[key] = this.tasks[key].map((e) =>
          e.id === task.id ? task : e,
        );
    }
  }

  @action.bound
  renameKey(oldKey: string, newKey: string) {
    this.chatInfo!.desks = this.chatInfo!.desks.map((e) =>
      e.name === oldKey ? { ...e, name: newKey } : e,
    );
  }

  @action.bound
  deleteDesk(deskId: number) {
    return this.chatInfo?.desks.map((e) =>
      e.id === deskId ? { ...e, deleted: 1 } : e,
    );
  }

  @action.bound
  setChatId(id: number) {
    this.chat_id = id;
  }

  @action.bound
  setUserId(id: number) {
    this.user_id = id;
  }

  @action.bound
  resetSelectedTask() {
    this.selectedTask = null;
  }

  @action.bound
  setEmptyTask() {
    this.selectedTask = {
      id: null,
      chat_id: 0,
      creator_id: 0,
      title: '',
      status: 0,
      deadline: null,
      subtasks: [],
      number: 0,
      created_at: '',
      updated_at: '',
      responsible: [],
      priority: 0,
      file: [],
      desk: this.chatInfo?.desks.find((e) => e.type === DeskTypes['NEW']),
      newFiles: [],
    };
  }

  @action.bound
  addFileToSelectedTask(file: IFile) {
    this.selectedTask?.file.push(file);
  }

  @action.bound
  setRootLoading(state: LoadState) {
    this.rootLoading = state;
  }
}
