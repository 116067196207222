export const AttachIcon = () => (
  <svg
    width='20'
    height='23'
    viewBox='0 0 20 23'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M16.2862 4.44497C13.9446 2.13838 10.0971 2.13837 7.75555 4.44497L2.40326 9.71729C2.01402 10.1007 1.38765 10.096 1.00423 9.70676C0.62081 9.31752 0.625525 8.69116 1.01476 8.30774L6.36706 3.03541C9.47884 -0.0298741 14.5629 -0.0298688 17.6747 3.03542C20.7362 6.05117 20.7362 10.9046 17.6747 13.9204L10.327 21.1583C7.97262 23.4774 4.12906 23.4774 1.77469 21.1583C-0.545665 18.8726 -0.545665 15.191 1.77469 12.9054L9.15558 5.63476C10.7397 4.07429 13.2964 4.00289 14.9694 5.46778C16.8073 7.07706 16.8466 9.87878 15.0518 11.5363L8.89293 17.224C8.49154 17.5946 7.86565 17.5698 7.49497 17.1684C7.12429 16.767 7.14919 16.1411 7.55058 15.7704L13.7094 10.0828C14.6394 9.22396 14.6208 7.79239 13.666 6.95636C12.7703 6.1721 11.3879 6.21308 10.5441 7.04431L3.16318 14.3149C1.6296 15.8256 1.6296 18.238 3.16318 19.7487C4.74735 21.3092 7.35433 21.3092 8.9385 19.7487L16.2862 12.5108C18.5609 10.2701 18.5609 6.68571 16.2862 4.44497Z'
      fill='#007AFF'
    />
  </svg>
);
