import { extraLightHapticFeedback } from '@common/telegramHaptic';
import { useStore } from '@hooks/storeHook';
import { TaskModal } from '@ui/TaskModal/TaskModal';
import { AnimatePresence } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { ReactNode, useEffect, useState } from 'react';

interface ITaskModalController {
  children: ReactNode;
}

export const TaskModalController = observer(
  ({ children }: ITaskModalController) => {
    const { selectedTask } = useStore();
    const [isShowModal, setIsShowModal] = useState(false);

    const hideModal = () => {
      //@ts-ignore
      Telegram.WebApp.MainButton.hide();

      setTimeout(() => {
        setIsShowModal(false);
      }, 200);
    };

    useEffect(() => {
      if (selectedTask) {
        extraLightHapticFeedback();
        setIsShowModal(true);
      }
    }, [selectedTask]);

    return (
      <>
        {children}

        <AnimatePresence>
          {isShowModal && <TaskModal onHide={hideModal} />}
        </AnimatePresence>
      </>
    );
  },
);
