import moment from 'moment';

export const calcateDeadline = (date: string | null) => {
  let calculatedDeadline: null | number = null;

  if (date) {
    const deadline = moment(date);
    const now = moment();
    calculatedDeadline = deadline.diff(now, 'days');
    if (calculatedDeadline >= 0) {
      calculatedDeadline++;
    }
    if (calculatedDeadline < 0) {
      calculatedDeadline = 0;
    }
  }
  return calculatedDeadline;
};

export const calculateThisWeekLastDay = () => {
  const now = moment();

  const nextFriday = now.clone().day(5);

  nextFriday.hour(19).minute(0).second(0).millisecond(0);

  return nextFriday.toDate();
};

export const calculateNextWeekLastDay = () => {
  const now = moment();

  const nextFriday = now.clone().day(5);

  if (!(now.day() === 5 && now.hour() >= 19) || now.day() < 5)
    nextFriday.add(7, 'days');

  nextFriday.hour(19).minute(0).second(0).millisecond(0);

  return nextFriday.toDate();
};
