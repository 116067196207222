import { useEffect } from 'react';

import { observer } from 'mobx-react-lite';

import { LoadState } from '@@types/loadState';
import { AppRoutesPaths, navigateTo } from '@config/navigation';
import { useStore } from '@hooks/storeHook';
import { useTelegram } from '@hooks/useTelegram';
import { Loader } from '@ui/Loader/Loader';
import { AnimatePresence, motion } from 'framer-motion';
import { KBoard } from './KBoard';
import { ProjectMenu } from './components/ProjectMenu';
import { TelegramHeader } from './components/TelegramHeader';

export type TaskStatus = 'active' | 'complete' | 'canceled';

export const goBack = () => {
  navigateTo(AppRoutesPaths.AllTasksAndChats);
};

export const HomePage = observer(() => {
  const { setEmptyTask, loading } = useStore();
  const isTg = useTelegram();

  useEffect(() => {
    // @ts-ignore
    const tg = Telegram.WebApp;

    tg.BackButton.show();

    tg.BackButton.onClick(goBack);

    return () => {
      tg.BackButton.hide();
      tg.BackButton.offClick(goBack);
    };
  }, []);

  const onClickHandler = async () => {
    setEmptyTask();
  };

  return (
    <div className='flex h-screen'>
      {!isTg && <ProjectMenu />}
      <motion.div
        className='w-full overflow-y-hidden max-h-screen overflow-scroll flex flex-col relative '
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.2 }}
        id='tasks-container'
      >
        {isTg && <TelegramHeader />}
        <AnimatePresence>
          {loading === LoadState.Loading && !isTg && <Loader />}
        </AnimatePresence>
        <KBoard />
        <div
          onClick={onClickHandler}
          className='absolute bottom-[35px] right-[35px]'
        >
          <AddTaskIcon />
        </div>
      </motion.div>
    </div>
  );
});

const AddTaskIcon = () => (
  <svg
    width='70'
    height='70'
    viewBox='0 0 70 70'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='35' cy='35' r='35' fill='#2AB65A' />
    <path
      d='M32.6242 49V21H37.3758V49H32.6242ZM21 37.3758V32.6242H49V37.3758H21Z'
      fill='white'
    />
  </svg>
);
