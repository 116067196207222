import { DeskTypes, ITask } from '@@types/types';
import { extraLightHapticFeedback } from '@common/telegramHaptic';
import { DatePickerDrawer } from '@pages/TaskPage/DatePickerDrawer';

import { appColors } from '@config/theme';
import { useLogic, useStore } from '@hooks/storeHook';
import { darkTheme, useTelegram } from '@hooks/useTelegram';
import { DateCircle } from '@ui/DateCircle/DateCircle';
import { DateSelector } from '@ui/EntitySelectors/DateSelector/DateSelector';
import { MiniModal } from '@ui/MiniModal/MiniModal';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { FC, useEffect, useRef, useState } from 'react';

interface IBoardCard {
  task: ITask;
  showProjectName?: boolean;
}

export const BoardCard: FC<IBoardCard> = observer(
  ({ task, showProjectName }) => {
    const logic = useLogic();
    const isTg = useTelegram();
    const { mergedSortedTasks, chatInfo } = useStore();

    const ref = useRef<HTMLDivElement>(null);
    const [parentElement, setParentElement] = useState<Element | null>(null);

    const parentRef = useRef<HTMLDivElement>(null);
    const [isInline, setIsInline] = useState(true);

    useEffect(() => {
      const handleResize = () => {
        if (parentRef.current) {
          // Adjust this threshold based on your requirements
          if (
            navigator.userAgent.toLowerCase().includes('chrome') ||
            navigator.userAgent.toLowerCase().includes('safari')
          )
            setIsInline(parentRef.current.clientHeight <= 209);
          else setIsInline(parentRef.current.clientHeight <= 167);
        }
      };

      handleResize(); // Initial check
      window.addEventListener('resize', handleResize);

      return () => window.removeEventListener('resize', handleResize);
    }, [parentRef.current]);

    const { allChats } = useStore();

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    useEffect(() => {
      setParentElement(ref.current);
    }, []);

    const onCloseDrawer = () => {
      extraLightHapticFeedback();
      setIsDrawerOpen(false);
    };

    const onSaveDateHandler = async (date: Date | null, priority?: true) => {
      logic.updateTask({
        ...mergedSortedTasks.find((e) => e.id === task.id)!,
        deadline: date ? moment(date).format('YYYY-MM-DD') : null,
        priority: priority ? 1 : 0,
      });
      extraLightHapticFeedback();
      setIsDrawerOpen(false);
    };

    const fetchTask = async () => {
      if (showProjectName) await logic.loadChatInfo(task.chat_id);
      await logic.getTask(task.chat_id, task.id!);
    };

    const onClickHandler = () => {
      setIsDrawerOpen(true);
    };

    return (
      <>
        <div
          style={{
            background: darkTheme ? appColors.darkCardBackground : 'white',
          }}
          className='gap-[15px] justify-between rounded-[12px] w-full px-[14px] pt-[7px] pb-[9px]  mb-[10px] text-[15px] leading-[22px] cursor-pointer'
          onClick={fetchTask}
        >
          <span
            className='card-text text-[15px] leading-[21px] align-middle select-none line-clamp-[10] hidden text-ellipsis'
            style={{
              color: darkTheme ? 'white' : 'black',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
            }}
            ref={parentRef}
          >
            {task?.title?.length && task?.title?.length > 0 ? (
              <>
                {chatInfo?.desks.find(
                  (e) => e.id === task.desk_id && e.type !== DeskTypes['DONE'],
                ) && (
                  <div ref={ref} className='inline-flex align-top'>
                    <DateCircle task={task} onClick={onClickHandler} />
                  </div>
                )}
                <span className='text-[15px] leading-[21px] text-blue'>
                  #{task.number}{' '}
                </span>
                {task.title}
                {task.subtasks.length !== 0 && isInline && (
                  <div className='min-w-[39px] h-[21px] bg-subTasksBackground rounded-[4px] inline-flex justify-center items-center text-white text-base mx-[5px]'>
                    {task.subtasks.reduce(
                      (prev, cur) =>
                        cur.status == 'complete' ? prev + 1 : prev,
                      0,
                    )}{' '}
                    / {task.subtasks.length}
                  </div>
                )}
                {task.responsible?.[0] &&
                  isInline &&
                  !showProjectName &&
                  (task.responsible[0].photo_url ? (
                    <img
                      src={task.responsible[0].photo_url}
                      className='object-contain rounded-[100px] w-[21px] h-[21px] bg-grey inline mx-[5px] align-middle'
                    />
                  ) : (
                    <div className='min-w-[21px] min-h-[21px] text-small font-bold text-white rounded-[100px] bg-blue inline-flex justify-center items-center mx-[5px] align-middle'>
                      {(task.responsible[0].last_name?.[0]?.toUpperCase() ??
                        '') +
                        (task.responsible[0].first_name?.[0]?.toUpperCase() ??
                          '')}
                    </div>
                  ))}
              </>
            ) : (
              'Задача без названия'
            )}
          </span>

          {task.subtasks.length !== 0 && !isInline && (
            <div className='min-w-[39px] h-[21px] bg-subTasksBackground rounded-[4px] inline-flex justify-center items-center text-white text-base mr-[5px]'>
              {task.subtasks.reduce(
                (prev, cur) => (cur.status == 'complete' ? prev + 1 : prev),
                0,
              )}{' '}
              / {task.subtasks.length}
            </div>
          )}

          {task.responsible?.[0] &&
            !isInline &&
            !showProjectName &&
            (task.responsible[0].photo_url ? (
              <img
                src={task.responsible[0].photo_url}
                className='object-contain rounded-[100px] w-[21px] h-[21px] bg-grey inline mr-[5px] align-middle'
              />
            ) : (
              <div className='min-w-[21px] min-h-[21px] text-small font-bold text-white rounded-[100px] bg-blue inline-flex justify-center items-center mx-[5px] align-middle'>
                {(task.responsible[0].last_name?.[0]?.toUpperCase() ?? '') +
                  (task.responsible[0].first_name?.[0]?.toUpperCase() ?? '')}
              </div>
            ))}

          {showProjectName && (
            <div
              className={`w-fit mt-[4px] rounded-[4px] ${darkTheme ? 'bg-[#4f4f4f] text-white' : 'bg-greySecondary text-greyText'} py-[2px] px-[24px] text-greyText text-[14px] tracking-[0]`}
            >
              {allChats.find((chat) => task.chat_id === chat.id)?.title}
            </div>
          )}
        </div>

        {parentElement && isDrawerOpen && !isTg && (
          <MiniModal
            parent={parentElement}
            direction='right'
            headerTitle='Дата выполнения'
            onHide={onCloseDrawer}
            body={<DateSelector task={task} onSave={onSaveDateHandler} />}
            classname='w-[390px]'
          />
        )}

        {isTg && (
          <DatePickerDrawer
            isVisible={isDrawerOpen}
            task={task}
            onSaveDateHandler={onSaveDateHandler}
            onCloseDrawer={onCloseDrawer}
          />
        )}
      </>
    );
  },
);
