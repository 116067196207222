import { appColors, getRandomColor } from '@config/theme';
import { useStore } from '@hooks/storeHook';
import { darkTheme } from '@hooks/useTelegram';
import IconExternalLink from '@pages/icons/externalLinkIcon';

export const TelegramHeader = () => {
  const { chatInfo } = useStore();

  const platform = window.Telegram.WebApp.platform;

  const onOpenWebVersion = () => {
    const tg = window.Telegram.WebApp;

    const decoded = tg.initData;

    tg.openLink(
      `https://dev.tontask.com/?isBrowser=true&chatId=${chatInfo?.id ?? 0}#${decoded}`,
    );

    // window.location.href = `http://localhost:3000/?isBrowser=true&chatId=${chatInfo?.id ?? 0}&initData=${decodeURIComponent(tg.initData)}`;
  };

  return (
    <div
      style={{
        background: darkTheme ? appColors.darkCardBackground : 'white',
      }}
      className='w-full p-[15px] flex items-center gap-[7px]'
    >
      <div
        style={{ backgroundColor: getRandomColor() }}
        className='min-w-[24px] h-[24px] rounded-[100px] font-bold flex justify-center items-center'
      >
        <span className='text-[12px] text-white'>
          {chatInfo?.title[0].toUpperCase()}
        </span>
      </div>
      <span
        style={{ color: darkTheme ? 'white' : 'black' }}
        className='text-large whitespace-nowrap overflow-hidden text-ellipsis'
      >
        {chatInfo?.title}
      </span>
      {platform !== 'android' && platform !== 'ios' && (
        <button
          className='ml-auto rounded-normal border-none text-[15px] leading-5 py-1 px-3 cursor-pointer flex items-center gap-2'
          onClick={onOpenWebVersion}
        >
          <span className='text-nowrap'>Web-версия</span>
          <IconExternalLink />
        </button>
      )}
    </div>
  );
};
