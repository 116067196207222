import ContactsIcon from '@assets/icons/contacts.png';
import ShareIcon from '@assets/icons/share.png';
import { extraLightHapticFeedback } from '@common/telegramHaptic';
import { ITabElement, TabList } from '@ui/TabList/TabList';

export const SharingBar = () => {
  //@ts-ignore
  const tg = Telegram.WebApp;

  const onAddBotToGroups = () => {
    extraLightHapticFeedback();
    tg.openTelegramLink('https://t.me/TgBoards_bot?startgroup=true');
  };

  const shareBotWithFriend = () => {
    if (tg.initDataUnsafe.user.language_code === 'ru') {
      extraLightHapticFeedback();
      tg.openTelegramLink(
        'https://t.me/share/url?url=https://t.me/TgBoards_bot&text=В%20телеграме%20появился%20встроенный%20таск-менеджер%20Boards.%20Добавляет%20задачи%20и%20канбан%20доски%20прямо%20в%20групповой%20чат.%20Очень%20удобно!%F0%9F%94%A5',
      );
    } else {
      extraLightHapticFeedback();
      tg.openTelegramLink(
        `https://t.me/share/url?url=https://t.me/TgBoards_bot&text=Telegram's%20got%20a%20new%20built-in%20task%20manager,%20Boards!%20Add%20tasks%20and%20Kanban%20boards%20directly%20in%20group%20chats.%20Super%20useful!%20%F0%9F%94%A5`,
      );
    }
  };

  const elements: ITabElement[] = [
    {
      mainIcon: (
        <img
          src={ContactsIcon}
          alt='Контакты'
          className='w-full h-full object-contain'
        />
      ),
      title: 'Добавить Boards в групповой чат',
      onClick: onAddBotToGroups,
      iconHeight: 32,
      iconWidth: 32,
    },
    {
      mainIcon: (
        <img
          src={ShareIcon}
          alt='Поделиться'
          className='w-full h-full object-contain'
        />
      ),
      title: 'Поделиться Boards с другом',
      onClick: shareBotWithFriend,
      iconHeight: 32,
      iconWidth: 32,
    },
  ];

  return <TabList elements={elements} />;
};
