import { DeskTypes } from '@@types/types';
import { extraLightHapticFeedback } from '@common/telegramHaptic';
import { appColors } from '@config/theme';
import { useLogic, useStore } from '@hooks/storeHook';
import { darkTheme, useTelegram } from '@hooks/useTelegram';
import { CloseDrawerIcon } from '@pages/icons/closeDrawer';
import { SettingButton } from '@pages/icons/settingButton';
import { SettingsPopup } from '@ui/SettingsPopup/SettingsPopup';
import { AnimatePresence } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { useRef, useState } from 'react';
import Drawer from 'react-bottom-drawer';
//@ts-ignore
import Board from 'react-trello';
import { BoardCard } from './BoardCard';

export const KBoard = observer(() => {
  const { chatInfo, mergedSortedTasks: mergedTasks, activeDesks } = useStore();
  const isTg = useTelegram();

  const tg = window.Telegram.WebApp;

  const logic = useLogic();

  const onAddBoard = () => {
    extraLightHapticFeedback();

    const name = prompt('Введите название доски');
    const existingDesk = chatInfo?.desks.find(
      (item) => item.name.toLowerCase() === name?.toLowerCase(),
    );
    if (name?.length === 0 || !name || existingDesk) {
      alert('Название не должно повторять существующее или быть пустым');
      tg.HapticFeedback.notificationOccurred('error');
      return;
    }

    logic.createBoard(name);
    extraLightHapticFeedback();
  };

  return (
    <Board
      editable
      canAddLanes
      components={{
        Card: (data: any) => <BoardCard task={data} />,
        AddCardLink: () => null,
        LaneHeader: ColumnHeader,
        NewLaneSection: () => (
          <div
            onClick={onAddBoard}
            style={{
              backgroundColor: appColors.green,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: isTg ? '80vw' : '320px',
              borderRadius: '12px',
              maxHeight: 'calc(100vh - 100px)',
              height: '55px',
              margin: '5px 5px',
            }}
          >
            <text className='text-large font-bold text-white'>
              Добавить доску
            </text>
          </div>
        ),
      }}
      handleDragEnd={async (
        cardId: any,
        sourceLaneId: any,
        targetLaneId: any,
        position: any,
        cardDetails: any,
      ) => {
        await logic.updateBoards(
          cardDetails,
          chatInfo?.desks.find((item) => item.id === targetLaneId)?.id ?? 0,
          position,
        );
        extraLightHapticFeedback();
      }}
      onCardClick={(id: any) => console.log(id)}
      cardStyle={{ minWidth: '100%' }}
      style={{
        backgroundColor: 'transparent',
        backgroundSize: 'cover',
        padding: '19px 10px',
      }}
      laneStyle={{
        backgroundColor: darkTheme ? 'black' : appColors.boardBackground,
        padding: '12px 8px',
        width: isTg ? '80vw' : '320px',
        borderRadius: '12px',
        maxHeight: 'calc(100vh - 100px)',
      }}
      data={{
        lanes: activeDesks.map((item) => ({
          id: item.id,
          cards: mergedTasks
            .filter((e) => e.desk_id === item.id)
            .map((cardItem) => ({
              description: cardItem.title,
              ...cardItem,
            })),
        })),
      }}
    />
  );
});

const ColumnHeader = observer((data: any) => {
  const { mergedSortedTasks, chatInfo, renameKey, activeDesks } = useStore();
  const desk = chatInfo?.desks.find((item) => item.id === data.id);
  const logic = useLogic();

  const [isSettingShow, setIsSettingShow] = useState(false);
  const [isDeskPickerShow, setDeskPickerShow] = useState(false);
  const [deskTitle, setDeskTitle] = useState(desk?.name ?? '');
  const refButton = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  //@ts-ignore
  const tg = Telegram.WebApp;

  const onShowSettings = () => {
    setIsSettingShow(true);
    extraLightHapticFeedback();
  };

  const onHideSettings = () => {
    setIsSettingShow(false);
    extraLightHapticFeedback();
  };

  const onRename = () => {
    setIsSettingShow(false);
    inputRef.current!.disabled = false;
    inputRef.current?.focus();
  };

  const onRemove = () => {
    if (
      mergedSortedTasks.filter(
        (task) => task.desk_id === data.id || task.desk?.id === data.id,
      ).length === 0
    )
      tg.showConfirm(
        `Удалить столбец ${desk?.name}?`,
        async (flag: boolean) => {
          if (flag && desk) {
            await logic.deleteBoard(desk!.id);
            extraLightHapticFeedback();
          }
        },
      );
    else {
      setIsSettingShow(false);
      setDeskPickerShow(true);
    }
  };

  const onSaveTitle = async () => {
    if (deskTitle.length === 0) return;
    inputRef.current!.disabled = true;
    await logic.updateBoard({ ...desk!, name: deskTitle });
    extraLightHapticFeedback();
    renameKey(desk!.name, deskTitle);
  };

  const onPressEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSaveTitle();
    }
  };

  const onBlur = () => {
    if (deskTitle.length === 0) setDeskTitle(desk!.name);
    onSaveTitle();
  };

  const onCloseDrawer = () => {
    setDeskPickerShow(false);
  };

  const onPickNewDesk = async (transferDeskId: number) => {
    await logic.deleteBoard(desk!.id, transferDeskId);
    extraLightHapticFeedback();
    setDeskPickerShow(false);
  };

  const onChangeDeskTitle = (str: string) => {
    setDeskTitle(str);
  };

  if (!desk) {
    return;
  }

  return (
    <>
      <div className='flex items-center'>
        <input
          ref={inputRef}
          value={deskTitle}
          onChange={(e) => onChangeDeskTitle(e.target.value)}
          onBlur={onBlur}
          onKeyDown={onPressEnter}
          style={{ color: darkTheme ? 'white' : 'black' }}
          className={`deskTitle text-large font-bold bg-transparent border-none pl-4 pr-2 py-[3.5px] rounded-[10px] focus:outline-none ${darkTheme ? 'focus:bg-darkCardBackground' : 'focus:bg-white'} transition-colors duration-[250ms]`}
        />

        <div
          onClick={onShowSettings}
          className='ml-auto h-[20px] flex justify-center items-center cursor-pointer relative mr-[13px]'
          ref={refButton}
        >
          <SettingButton fill={darkTheme ? 'white' : 'black'} />
        </div>
      </div>
      <AnimatePresence>
        {isSettingShow && (
          <SettingsPopup
            refElement={refButton.current!}
            onHide={onHideSettings}
            buttons={[
              { title: 'Переименовать', callback: onRename },
              ...(desk.type !== DeskTypes['NEW'] &&
              desk.type !== DeskTypes['DONE']
                ? [
                    {
                      title: 'Удалить',
                      callback: onRemove,
                      textColor: appColors.red,
                    },
                  ]
                : []),
            ]}
          />
        )}
      </AnimatePresence>
      <Drawer
        onClose={onCloseDrawer}
        className='drawer'
        isVisible={isDeskPickerShow}
      >
        <div
          onClick={onCloseDrawer}
          className='absolute right-[15px] top-[15px]'
        >
          <CloseDrawerIcon />
        </div>
        <div className='flex flex-col w-full gap-[15px]'>
          <text className='text-large font-bold w-full text-center'>
            Новая доска для переноса задач
          </text>
          {activeDesks
            .filter((e) => e.id !== data.id)
            .map((desk) => (
              <div
                onClick={() => onPickNewDesk(desk.id)}
                className='w-full flex items-center gap-[5px] border-solid  border-0 border-border-grey border-b-[1px] py-[10px]'
                key={desk.id}
              >
                <text className='text-large font-bold'>
                  {/* {userName.length > 11
                    ? userName.slice(0, 11) + '...'
                    : userName} */}
                  {desk.name}
                </text>
                <text className='text-large text-blue ml-auto cursor-pointer'>
                  Выбрать
                </text>
              </div>
            ))}
        </div>
      </Drawer>
    </>
  );
});
