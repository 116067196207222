import { useTelegram } from '@hooks/useTelegram';
import { CrossIcon } from '@pages/icons/crossIcon';
import { TaskPage } from '@pages/TaskPage/TaskPage';
import { motion } from 'framer-motion';
import { FC } from 'react';
import ReactDOM from 'react-dom';
import { useSwipeable } from 'react-swipeable';

export interface ITaskModal {
  onHide: () => void;
}

export const TaskModal: FC<ITaskModal> = ({ onHide }) => {
  const isTg = useTelegram();
  const handlers = useSwipeable({
    onSwipedDown: () => onHide(),
    swipeDuration: 0,
    trackMouse: true,
  });

  return ReactDOM.createPortal(
    <motion.div
      className={`absolute inset-0 z-[9999] ${!isTg && 'pl-[340px]'}`}
      onClick={onHide}
      initial={{
        backgroundColor: 'rgba(0,0,0,0)',
      }}
      animate={{ backgroundColor: 'rgba(0,0,0,.70)' }}
      exit={{
        backgroundColor: 'rgba(0,0,0,0)',
      }}
      transition={{ duration: 0.2 }}
    >
      <motion.section
        className='bg-white w-full h-full rounded-[20px_20px_0_0] flex flex-col'
        onClick={(e) => e.stopPropagation()}
        initial={{
          translateY: '100vh',
          borderRadius: '40px 40px 0 0',
        }}
        animate={{ translateY: '0%', borderRadius: '0' }}
        exit={{
          translateY: '100%',
          borderRadius: '40px 40px 0 0',
        }}
        transition={{ duration: 0.15 }}
        {...handlers}
      >
        {!isTg && (
          <div className='bg-blue min-h-[58px] relative flex items-center pl-[18px]'>
            <button
              className='absolute top-0 -left-[58px] w-[58px] h-[58px] bg-blue m-0 border-none p-0 rounded-[12px_0_0_12px] flex items-center justify-center cursor-pointer'
              onClick={onHide}
            >
              <CrossIcon />
            </button>
            <p className='text-white text-[22px]'>Редактирование задачи</p>
          </div>
        )}
        <TaskPage onHide={onHide} />
      </motion.section>
    </motion.div>,
    document.getElementById('root')!,
  );
};
