import { motion } from 'framer-motion';

export const Loader = () => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.2 }}
    className='absolute inset-0 backdrop-blur-md flex items-center justify-center pointer-events-none z-[9999999]'
  >
    <div className='w-[140px] h-[140px] scale-[65%] bg-white bg-icon-gradient rounded-[21px] py-[21px] px-[21px] flex justify-between gap-[7px] drop-shadow-[1.4_0.14px_0.56px_rgb(0_0_0_/_0.2)]'>
      <motion.div
        className='bg-white w-full rounded-[2.1px] drop-shadow-[5.3_0.28px_0.28px_rgb(0_0_0_/_0.15)]'
        animate={{
          height: ['23%', '100%', '23%'],
        }}
        transition={{ repeat: Infinity, duration: 3 }}
      />
      <motion.div
        className='bg-white w-full rounded-[2.1px] drop-shadow-[5.3_0.28px_0.28px_rgb(0_0_0_/_0.15)]'
        animate={{ height: ['5%', '55.6%', '5%'] }}
        transition={{ repeat: Infinity, duration: 1 }}
      />
      <motion.div
        className='bg-white w-full rounded-[2.1px] drop-shadow-[5.3_0.28px_0.28px_rgb(0_0_0_/_0.15)]'
        animate={{
          height: ['67.7%', '14%', '67.7%'],
        }}
        transition={{ repeat: Infinity, duration: 1 }}
      />
    </div>
  </motion.div>
);
