import { ITask } from '@@types/types';
import { extraLightHapticFeedback } from '@common/telegramHaptic';
import { CheckBox } from '@pages/icons/checkBox';
import { EnterIcon } from '@pages/icons/enterIcon';
import { observer } from 'mobx-react-lite';
import { useRef, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { SubTasks } from './SubTasks';

export interface ITaskAndSubtasksComponent {
  setTask: (field: string, value: ITask[keyof ITask]) => void;
  task: ITask;
}

export const TaskAndSubtasksComponent = observer(
  ({ setTask, task }: ITaskAndSubtasksComponent) => {
    const subtasks = task?.subtasks;

    const subTaskTextRef = useRef<any>();

    const [subTaskTitleInput, setInput] = useState('');

    const onChangeTitle = (e: any) => {
      setTask('title', e.target.value);
    };

    const handleSubTaskTitleInput = (event: any) => {
      setInput(event.currentTarget.value);
    };

    function handleCreateSubTask() {
      setTask('subtasks', [
        ...task.subtasks,
        {
          id: task.subtasks.length,
          task_id: 0,
          title: subTaskTitleInput,
          status: 'active',
          created_at: '',
          updated_at: '',
        },
      ]);
      extraLightHapticFeedback();

      setInput('');
    }

    return (
      <section className='flex flex-col gap-[19px] pt-[17px] pb-[14px]'>
        <TextareaAutosize
          value={task?.title ?? ''}
          onChange={onChangeTitle}
          minRows={1}
          maxRows={6}
          placeholder='Текст новой задачи'
          defaultValue={task?.title ?? ''}
          className='bg-transparent text-large p-0 w-full text-black focus:outline-none !border-none  shadow-none -tracking-[0.4] resize-none'
        />
        <div className='flex flex-col  gap-[15px]'>
          {subtasks?.map((subtask, index) => (
            <SubTasks
              subtask={subtask}
              index={index}
              key={subtask.id}
              setTask={setTask}
              task={task}
            />
          ))}
          <div className='flex w-full items-center'>
            <div className='min-w-[20px]'>
              <CheckBox />
            </div>
            <div className='flex ml-[10px] w-full items-center'>
              <div className='flex w-full items-center gap-[5px] border-solid  border-0 border-border-grey border-b-[1px] mr-[10px]'>
                <TextareaAutosize
                  value={subTaskTitleInput}
                  onChange={handleSubTaskTitleInput}
                  ref={subTaskTextRef}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      handleCreateSubTask();

                      subTaskTextRef.current.selectionStart = 0;
                      subTaskTextRef.current.selectionEnd = 0;
                    }
                  }}
                  onBlur={() => {
                    if (subTaskTitleInput.length > 0) {
                      handleCreateSubTask();

                      subTaskTextRef.current.selectionStart = 0;
                      subTaskTextRef.current.selectionEnd = 0;
                    }
                  }}
                  minRows={1}
                  maxRows={6}
                  className='w-full text-[15px] bg-transparent text-grey-text focus:outline-none !border-none  shadow-none !p-0 !pb-[2px] tracking-[0] resize-none'
                  placeholder='Текст новой подзадачи'
                />
              </div>
            </div>
            <div onClick={handleCreateSubTask} className='h-[20px] ml-auto'>
              <EnterIcon />
            </div>
          </div>
        </div>
      </section>
    );
  },
);
