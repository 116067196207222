import { exact } from '@common/tsUtils';
import { AppContext } from '@context/appContexts';
import { Logic } from '@logic/logic';
import { Store } from '@store/store';
import { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';

import { useAnyEffect } from '@hooks/commonHooks';

export const withConfigs = (WrappedComponent: () => JSX.Element) => () => {
  const [appContext] = useState(() => {
    const store = new Store();

    return exact<AppContext>({
      store,
      logic: new Logic(store),
    });
  });

  const initTelegram = () => {
    const tg = window.Telegram.WebApp;
    tg.expand();
  };

  useAnyEffect(() => {
    initTelegram();
  });

  return (
    <BrowserRouter>
      <AppContext.Provider value={appContext}>
        <WrappedComponent />
      </AppContext.Provider>
    </BrowserRouter>
  );
};
