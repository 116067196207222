export const CheckBox = ({ active }: { active?: boolean }) =>
  active ? (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='20' height='20' rx='4' fill='#007AFF' />
      <path
        d='M8 10.25L9.76471 12L13 8'
        stroke='white'
        stroke-width='1.8'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  ) : (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect x='0.5' y='0.5' width='19' height='19' rx='3.5' stroke='#C8C7CB' />
    </svg>
  );
