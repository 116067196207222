import { ITask } from '@@types/types';
import FlameIcon from '@assets/icons/flame.png';
import { CalendarIcon } from '@pages/icons/calendarIcon';
import { CloseDrawerIcon } from '@pages/icons/closeDrawer';
import { MoreActionsIcon } from '@pages/icons/moreActionsIcon';
import { CalendarComponent } from '@ui/Calendar/Calendar';
import {
  calculateNextWeekLastDay,
  calculateThisWeekLastDay,
} from '@utils/calculateDeadline';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import Drawer from 'react-bottom-drawer';
import ReactDOM from 'react-dom';

interface IDatePickerDrawer {
  isVisible: boolean;
  task: ITask;
  onSaveDateHandler: (date: Date | null, priority?: true) => void;
  onCloseDrawer: () => void;
}

export const DatePickerDrawer: FC<IDatePickerDrawer> = ({
  isVisible,
  task,
  onSaveDateHandler,
  onCloseDrawer,
}) => {
  const [isShowCalendar, setIsShowCalendar] = useState(false);

  useEffect(() => {
    if (!isVisible) setIsShowCalendar(false);
  }, [isVisible]);

  return ReactDOM.createPortal(
    <Drawer
      onClose={() => onCloseDrawer()}
      className={`${isShowCalendar ? 'drawer-white' : 'drawer-grey'}`}
      isVisible={isVisible}
    >
      <div
        onClick={() => onCloseDrawer()}
        className='absolute right-[15px] top-[15px]'
      >
        <CloseDrawerIcon />
      </div>
      <div className='w-full items-center flex flex-col'>
        <div className=' w-full flex justify-center items-center mb-[10px] px-[15px]'>
          <text className='text-large font-medium !tracking-[-0.4px]'>
            Дата выполнения
          </text>
        </div>

        {!isShowCalendar && (
          <div className='bg-white flex flex-col rounded-normal w-full py-[2px] mb-[40px] mt-[20px]'>
            <div
              className='flex gap-4 py-[12px] pl-4 items-center'
              onClick={() => onSaveDateHandler(new Date(), true)}
            >
              <div className='flex min-w-[21px]  min-h-[21px] justify-center items-center'>
                <img src={FlameIcon} width={14} height={18} />
              </div>
              <p className='m-0 font-normal text-lg'>Срочно</p>
            </div>
            <div className='border-0 border-t-[0.5px] border-solid border-[#d9d9d9] w-[calc(100%_-_60px)] self-end' />
            {(!(moment().day() === 5 && moment().hour() >= 19) ||
              moment().day() < 5) && (
              <>
                <div
                  className='flex gap-4 py-[12px] pl-4 items-center'
                  onClick={() => onSaveDateHandler(calculateThisWeekLastDay())}
                >
                  <div className=' flex min-w-[21px]  min-h-[21px] justify-center rounded-[100px] items-center bg-green'>
                    <span className='text-[13px] text-white'>I</span>
                  </div>
                  <p className='m-0 font-normal text-lg'>Текущая неделя</p>
                </div>
                <div className='border-0 border-t-[0.5px] border-solid border-[#d9d9d9] w-[calc(100%_-_60px)] self-end' />
              </>
            )}
            <div
              className='flex gap-4 py-[12px] pl-4 items-center'
              onClick={() => onSaveDateHandler(calculateNextWeekLastDay())}
            >
              <div className=' flex min-w-[21px]  min-h-[21px] justify-center rounded-[100px] items-center bg-blue'>
                <span className='text-[13px] text-white'>II</span>
              </div>
              <p className='m-0 font-normal text-lg'>Следующая неделя</p>
            </div>
            <div className='border-0 border-t-[0.5px] border-solid border-[#d9d9d9] w-[calc(100%_-_60px)] self-end' />
            <div
              className='flex gap-4 py-[12px] pl-4 items-center'
              onClick={() => setIsShowCalendar(true)}
            >
              <div className='relative -left-[2px] w-[21px] h-[23px]'>
                <CalendarIcon width={21} height={23} fill='#A2ACB0' />
              </div>
              <p className='m-0 font-normal text-lg'>Выбрать дату</p>
            </div>
            <div className='border-0 border-t-[0.5px] border-solid border-[#d9d9d9] w-[calc(100%_-_60px)] self-end' />
            <div
              className='flex gap-4 py-[12px] pl-4 items-center'
              onClick={() => onSaveDateHandler(null)}
            >
              <div className='rotate-90 w-[21px] h-[21px]'>
                <MoreActionsIcon width={21} height={21} />
              </div>
              <p className='m-0 font-normal text-lg'>Без даты</p>
            </div>
          </div>
        )}

        {isShowCalendar && (
          <CalendarComponent
            onSave={(date) => onSaveDateHandler(date)}
            defaultValue={task.deadline && moment(task.deadline).format('')}
          />
        )}
      </div>
    </Drawer>,
    document.body,
  );
};
