import { darkTheme } from '@hooks/useTelegram';
import { FC } from 'react';

export interface ITabElement {
  mainIcon: JSX.Element | string;
  title: string;
  onClick: () => void;
  iconHeight: number;
  iconWidth: number;
  subtitle?: string;
}

interface ITabList {
  elements: ITabElement[];
  isDarkTheme?: boolean;
}

export const TabList: FC<ITabList> = ({
  elements,
  isDarkTheme = darkTheme,
}) => (
  <ul
    className={`${!isDarkTheme ? 'bg-white text-black' : ' bg-darkCardBackground text-white'} w-full flex flex-col rounded-normal px-0`}
  >
    {elements.map((e, i) => (
      <div key={i}>
        <li
          className='flex gap-4 py-[7px] items-center px-4 cursor-pointer'
          onClick={e.onClick}
        >
          {typeof e.mainIcon === 'string' ? (
            <img
              src={e.mainIcon}
              className='rounded-[8px]'
              style={{ width: e.iconWidth, height: e.iconHeight }}
            />
          ) : (
            <div style={{ width: e.iconWidth, height: e.iconHeight }}>
              {e.mainIcon}
            </div>
          )}
          <div className='h-full w-full flex flex-col gap-[2px] justify-center overflow-hidden'>
            <p className='m-0 p-0 text-[17px] whitespace-nowrap overflow-hidden text-ellipsis'>
              {e.title}
            </p>
            {e.subtitle && (
              <p className='m-0 p-0 text-[15px] text-grey-text first-letter:uppercase'>
                {e.subtitle}
              </p>
            )}
          </div>
        </li>
        {i !== elements.length - 1 && (
          <div
            className={`border-0 border-t-[0.5px] border-solid border-[#d9d9d9] self-end`}
            style={{ width: `calc(100% - ${e.iconWidth + 16 + 16}px)` }}
          />
        )}
      </div>
    ))}
  </ul>
);
