import { appColors } from '@config/theme';
import { motion } from 'framer-motion';

interface ISwitcher {
  elements: string[];
  activeIndex: number;
  onClick: (index: number) => void;
}

export const Switcher = ({ elements, activeIndex, onClick }: ISwitcher) => (
  <div
    style={{
      background: appColors.greySlider,
    }}
    className='bg-boardBackground w-full min-h-[32px] p-[2px] rounded-[9px] flex items-center relative'
  >
    <motion.div
      layout
      transition={{ type: 'spring', stiffness: 300, damping: 30 }}
      style={{
        zIndex: 0,
        boxShadow: '0px 3px 1px 0px rgba(0, 0, 0, 0.04)',
        backgroundColor: 'white',
        position: 'absolute',
        top: '2px',
        bottom: '2px',
        borderRadius: '9px',
        width: `calc(100% / 2 - 4px)`,
        left: `calc(${activeIndex * 50}% + 2px)`,
      }}
    />
    {elements.map((item, index) => (
      <div
        key={index}
        className='w-full text-bigger h-full flex justify-center items-center cursor-pointer z-10 tracking-sm text-black'
        onClick={() => onClick(index)}
      >
        {item}
      </div>
    ))}
  </div>
);
