import { useLogic, useStore } from '@hooks/storeHook';
import { BoardCard } from '@pages/HomePage/BoardCard';
import { TabComponent } from '@ui/TabComponent/TabComponent';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { ChatCard } from './ChatCard';

export const TasksAndChats = observer(() => {
  const store = useStore();
  const logic = useLogic();
  useEffect(() => {
    logic.getAllTasksAndChats();
  }, []);

  return (
    <motion.div
      className='w-screen h-screen overflow-auto flex flex-col'
      exit={{ x: '-100%' }}
      transition={{ duration: 0.2 }}
    >
      <TabComponent
        items={[
          {
            name: 'Мои задачи',
            render: (
              <div className='w-full h-full p-[20px] flex flex-col'>
                {[].map((item) => (
                  <BoardCard task={item} showProjectName={true} />
                ))}
              </div>
            ),
          },
          {
            name: 'Мои проекты',
            render: (
              <div className='w-full h-full p-[20px] flex flex-col gap-[10px]'>
                {store.allChats.map((item) => (
                  <ChatCard item={item} />
                ))}
              </div>
            ),
          },
        ]}
      />
    </motion.div>
  );
});
