import { NextIcon } from '@pages/icons/nextIcon';
import { PrevIcon } from '@pages/icons/prevIcon';
import { PropsWithChildren } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './styles.css';

import { extraLightHapticFeedback } from '@common/telegramHaptic';
import { LooseValue, Value } from 'react-calendar/dist/cjs/shared/types';

export const CalendarComponent = ({
  defaultValue,
  onSave,
}: PropsWithChildren & {
  defaultValue?: LooseValue | undefined;
  onSave: (date: any) => void;
}) => {
  const onChangeDate = (value: Value) => {
    extraLightHapticFeedback();
    onSave(value);
  };

  return (
    <div className='w-full flex flex-col justify-center items-center'>
      <div className='w-[290px] min-h-[334px] bg-[white] pt-[10px] rounded-[8px] flex flex-col'>
        <Calendar
          defaultValue={defaultValue}
          onChange={(value) => onChangeDate(value)}
          prevLabel={<PrevIcon />}
          nextLabel={<NextIcon />}
          next2Label={null}
          prev2Label={null}
          locale='Ru-ru'
          minDate={new Date()}
        />
      </div>
    </div>
  );
};
