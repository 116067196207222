import {
  IAllTasks,
  IChat,
  IChatInfo,
  IDesk,
  IFile,
  ITask,
} from '@@types/types';
import { VisibleError } from '@common/visibleError';
// @ts-ignore

import Axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface ApiResponse<T = any> {
  data: T;
  message: string;
  status: number;
  success: boolean;
}

export class ApiLogic {
  private readonly axios: AxiosInstance;

  constructor(initData: string) {
    this.axios = Axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        Accept: 'application/json',
        test: process.env.REACT_APP_DEV,
        userId: process.env.REACT_APP_DEV === 'false' ? null : 433945518,
        initData: initData,
      },
      timeout: 30 * 1000,
    });

    this.axios.interceptors.request.use(
      this.interceptRequest,
      this.interceptRequestError,
    );

    this.axios.interceptors.response.use(
      this.interceptResponse,
      this.interceptResponseError,
    );
  }

  private interceptRequest = async (request: AxiosRequestConfig) => {
    console.log(
      `[REQUEST] ${request.method} => ${request.baseURL}${request.url}`,
      {
        data: JSON.stringify(request.data),
      },
    );

    return request;
  };

  private interceptRequestError = (error?: Partial<AxiosError>) => {
    if (Axios.isCancel(error)) {
      console.log(
        `[REQUEST CANCEL] ${error?.config?.method} ${error?.config?.url}`,
        { data: error?.config?.data },
      );
      throw new VisibleError('Запрос отменён', error);
    } else {
      console.log(
        `[REQUEST ERROR] ${error?.config?.method} ${error?.config?.url}`,

        JSON.stringify(error),
      );

      throw error;
    }
  };

  private interceptResponse = (response: AxiosResponse) => {
    console.log(
      `[RESPONSE] ${response.config.method} ${response.config.url} => ${response.status}`,
      //, {data: JSON.stringify(response.data, null, 2)}
    );

    return response.data;
  };

  private interceptResponseError = async (error?: Partial<AxiosError>) => {
    if (Axios.isCancel(error)) {
      console.log(
        `[RESPONSE CANCEL] ${error?.config?.method} ${error?.config?.url}`,
      );
      throw new VisibleError('Запрос отменён', error);
    }

    if (error?.response) {
      const { status, data } = error.response;

      console.log(
        `[RESPONSE ERROR] ${error?.config?.method} ${error?.config?.url} => ${status}`,
        {
          responseData: JSON.stringify(data, null, 2),
        },
      );
    } else {
      console.log(
        `[RESPONSE ERROR] ${error?.config?.method} ${error?.config?.url} => no response`,
        JSON.stringify(error),
      );
    }

    throw error;
  };

  getTasks = async (chatId: number) =>
    this.axios.get<IAllTasks>(`api/${chatId}/tasks`, {}).then(getData);

  getAllTasks = async (chatId: number) =>
    this.axios
      .get<ITask[]>(`api/my/tasks`, {
        params: { chat_id: chatId },
      })
      .then(getData);

  getAllChats = async (chatId: number) =>
    this.axios
      .get<IChat[]>(`api/my/chats?is_bot_deleted=0`, {
        params: { chat_id: chatId },
      })
      .then(getData);

  getChatInfo = async (chatId: number) =>
    this.axios.get<IChatInfo>(`api/chats/${chatId}`, {}).then(getData);

  deleteTask = async (chatId: number, taskId: number) =>
    this.axios
      .post<IAllTasks>(`api/${chatId}/tasks/${taskId}`, {
        _method: 'DELETE',
      })
      .then(getData);

  updateBoards = async (
    taskId: number,
    desk_id: number,
    position: number,
    chat_id: number,
  ) =>
    this.axios
      .post<IAllTasks>(
        `api/${chat_id}/tasks/${taskId}`,
        {
          desk_id,
          position,
        },
        {
          params: {
            _method: 'PATCH',
          },
        },
      )
      .then(getData);

  createBoard = async (name: string, chat_id: number) =>
    this.axios
      .post<IAllTasks>(
        `api/${chat_id}/desks`,
        { name },
        {
          params: {},
        },
      )
      .then(getData);

  deleteBoard = async (
    desk_id: number,
    chat_id: number,
    transferDeskId?: number,
  ) =>
    this.axios
      .post<IAllTasks>(
        `api/${chat_id}/desks/${desk_id}`,
        {},
        {
          params: { _method: 'DELETE', desk_id: transferDeskId ?? undefined },
        },
      )
      .then(getData);

  updateBoard = async (desk: IDesk, chat_id: number) =>
    this.axios
      .post<IAllTasks>(
        `api/${chat_id}/desks/${desk.id}`,
        { ...desk },
        {
          params: {
            _method: 'PATCH',
          },
        },
      )
      .then(getData);

  updateTask = async (task: ITask) =>
    this.axios
      .post<IAllTasks>(
        `api/${task.chat_id}/tasks/${task.id}`,
        { ...task },
        {
          params: {
            _method: 'PATCH',
            desk_id: task.desk?.id ?? task.desk_id,
          },
        },
      )
      .then(getData);

  getTask = (chatId: number, taskId: number) =>
    this.axios.get<ITask>(`api/${chatId}/tasks/${taskId}`).then(getData);

  createTask = (chatId: number, formData: FormData) =>
    this.axios
      .post<{
        tasks: IAllTasks;
        created_task: ITask;
      }>(`api/${chatId}/tasks`, formData)
      .then(getData);

  addFileToTask = async (chatId: number, taskId: number, formData: FormData) =>
    this.axios
      .post<IFile>(`api/${chatId}/tasks/${taskId}/file/add`, formData)
      .then(getData);

  getLinkToDownload = async (chatId: number, taskId: number, fileId: string) =>
    this.axios.get<IAllTasks>(`tasks/${taskId}/files/${fileId}`).then(getData);

  private authConfig = (authToken: string) =>
    ({ headers: { ['Authorization']: `Bearer ${authToken}` } }) as const;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getData = <T>(response: AxiosResponse<T>) => response.data;

// export async function createChat(chat: IChat) {
//   await axios.post<ISubTask[]>(`${host}/api/chats`, {
//     ...chat,
//     _method: 'PATCH'
//   }, { headers: { Accept: 'application/json' } });
// }

// export async function getChatUsers(chatId: number) {
//   await axios.get<IUser[]>(`${host}/api/${chatId}/users`, { headers: { Accept: 'application/json' } });
// }
