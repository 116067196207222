import { FC } from 'react';

interface IMoreActionsIcon {
  width?: number;
  height?: number;
  fill?: string;
  transform?: string;
}

export const MoreActionsIcon: FC<IMoreActionsIcon> = ({
  width = 30,
  height = 30,
  fill = '#AEAEB2',
  transform,
}) => (
  <svg
    width={width.toString()}
    height={height.toString()}
    viewBox='0 0 30 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    transform={transform}
  >
    <circle cx='15' cy='15' r='15' transform='rotate(-90 15 15)' fill={fill} />
    <circle
      cx='15.1'
      cy='15.0001'
      r='1.6'
      transform='rotate(-90 15.1 15.0001)'
      fill='white'
    />
    <circle
      cx='15.1'
      cy='21.4'
      r='1.6'
      transform='rotate(-90 15.1 21.4)'
      fill='white'
    />
    <circle
      cx='15.1'
      cy='8.59995'
      r='1.6'
      transform='rotate(-90 15.1 8.59995)'
      fill='white'
    />
  </svg>
);
